@import '../../../global-vars.scss';

.nav-bar-is-open {
    transform: translateY(0);
}

.nav-bar-is-closed {
    transform: translateY(-72px);
}

.nav-bar {
    display: flex;
    position: fixed;
    top: 0;
    box-shadow: 0 2px 4px rgba($color: #000000, $alpha: 0.5);
    background-color: $primary-color;
    width: 100%;
    height: 72px;
    z-index: 5;
    align-items: center;
    font-size: 1rem;
    transition: transform 300ms 300ms cubic-bezier(0.4, 0, 0.2, 1), opacity 0ms 300ms, background-color 150ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 1;

    // Leading container 
    .leading-container {
        display: flex;
        align-items: center;
        height: 100%;
        flex-grow: 10;
        margin-right: 1rem;
        .navbar-logo {
            margin-left: 1rem;
            width: auto;
            height: 50px;
        }
    }
    
    .trailing-container {
        display: flex;
        flex-grow: 1;
    }

    ul {
        list-style-type: none;
    }

    a {
        transition: opacity 50ms 50ms cubic-bezier(0.075, 0.82, 0.165, 1);
        color: #fff;
        padding-left: 10px;
        padding-right: 10px;
        font-weight: 400;
        font-size: 0.8375rem;
    }

    ul:hover > a:not(:hover) {
        opacity: 0.5;
    }

    a:hover {
        opacity: 1.0;
    }

    .button {
        display: none;
    }
}

@media (max-width: 60em) {

    .leading-container {
        flex-grow: 3 !important;
    }

    .trailing-container {
        display: none !important;
    }

    .button {
        display: flex !important;
        flex-direction: column;
        flex-grow: 1;

        div {
            height: 1px;
            width: 2rem;
            background: white;
            margin: 5px 0;
        }
    }

    .drop-down {
        position: absolute;
        top: 72px;
        right: 0;
        margin: 0 2rem;
        background: white;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        animation:fadein 0.7s linear;

        .trailing-drop {
            display: flex;
            flex-direction: column;
            padding: 0 2rem;

            a {
                margin: 1rem 0;
                color: black;
                font-size: 20px;
            }
        }
    }
}

@keyframes fadein {
	from {
		top:0;
	}
	to {
		top:72px;
	}
}